import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../../components/Layout";
import EditeurForm from "../../components/EditeurForm";

class InformationEditeurForm extends React.Component {
  render() {
    return (
      <EditeurForm name="informations-editeur" isEnregistrementForm={false}/>
    )
  }
}


const InformationEditeur = ({data}) => {
  const { footerData, navbarData } = data;
  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <title>Confirmation</title>
      </Helmet>
      <InformationEditeurForm/>
    </Layout>
  )
}

export default InformationEditeur;


export const informationEditeurQuery = graphql`
  query InformationEditeur {
    ...LayoutFragment
  }
`